<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-center">
          <h4>
            {{ title }}
          </h4>
        </div>

        <table class="table table-bordered">
          <tr>
            <th>TINDAKAN</th>
            <template v-for="(employee, i) in employees">
              <th :key="i"><span>SR. {{ employee.name.split(" ")[0] }}</span></th>
            </template>
          </tr>
          <template v-for="(item, i) in data">
            <tr :key="i">
              <td>{{ item.name }}</td>
              <template v-for="(empl, ind) in item.employees">
                <td :key="ind">
                  <div>A : {{ empl.assistant }}</div>
                  <div>O : {{ empl.omploop }}</div>
                </td>
              </template>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export default {
  components: {
    Card,
  },

  data() {
    return {
      title: "LAPORAN TINDAKAN BULAN JANUARI 2024",
      filter: {
        start_date: "",
        end_date: "",
      },
      data: [],
      employees: [],
    };
  },

  methods: {
    async get() {
      console.log("clicks");
      this.filter.start_date = "2024-05-01";
      this.filter.end_date = "2024-05-31";
      let filterParams = `start_date=${this.filter.start_date}%2000:00:00&end_date=${this.filter.end_date}%2023:59:59`;
      this.data = await module.getMedicalActionReport(
        `medical-action-report?${filterParams}`
      );
      console.log("data", this.data);
    },
    async getEmployee() {
      let filterParams = `department_id=1&sort_by=name&sort_type=asc&start_date=${this.filter.start_date}%2000:00:00&end_date=${this.filter.end_date}%2023:59:59`;

      this.employees = await module.get(`employees?${filterParams}`);
      console.log("employees", this.employees);
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Tindakan Medis", route: "/medical-actions" },
      { title: "Daftar Tindakan Medis" },
    ]);

    this.get();
    this.getEmployee();
  },
};
</script>

<style>
.nowrap {
  white-space: normal;
}

.th-action {
  white-space: nowrap !important;
}

.td-center {
  text-align: center !important;
}

@media (max-width: 767.98px) {
  .nowrap {
    white-space: nowrap;
  }
}
</style>